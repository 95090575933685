<template>
    <div class="page-wrap">
        <div class="session-form-hold">
            <base-card>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="primary"
                ></v-progress-linear>
                <v-card-text class="text-center">
                    <v-avatar size="60" class="mb-4">
                        <img src="@/assets/images/logo.png" alt="" />
                    </v-avatar>

                    <h6 class="text--disabled font-weight-medium mb-10">
                        Ingrese con su cuenta
                    </h6>
                    <v-form>
                        <v-text-field
                            label="Usuario"
                            v-model="user.username"
                           
                            required
                        />

                        <v-text-field
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show ? 'text' : 'password'"
                            name="input-10-2"
                            label="Password"
                            v-model="user.password"
                            @click:append="show = !show"
                        ></v-text-field>
                   <!--     <v-checkbox
                            v-model="checkbox1"
                            label="Remember this computer"
                        ></v-checkbox>-->
                        <v-btn
                            class="mb-4"
                            @click="send_login"
                            block
                            color="primary"
                            dark
                        >
                            <v-icon left>mdi-login</v-icon>
                            Ingresar</v-btn
                        >
                      
                      
                    </v-form>
                </v-card-text>
            </base-card>
        </div>
    </div>
</template>
<script>
import { url } from "../main.js"

  export default {
    data () {
      return {
          loading: false,
           show: false,
        user:{
        username: '',
        password: ''
        },
        error:""
      }
    },  methods: {
    send_login() {
       console.log(this.user);
     if (this.user.username != "" && this.user.password != "") {
        this.axios
          .post(
            url+"apiUsers.php/login",
            this.user
          )
          .then(respuesta => {
           console.log(respuesta);
               this.loading = true
          if (respuesta.data != null) {
              localStorage.setItem('iduser',respuesta.data.iduser);
              localStorage.setItem('username', respuesta.data.user);
              localStorage.setItem('idprofile', respuesta.data.idprofile);
              this.$router.push("/main");

            }
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.error = 'Ingrese usuario y contraseña';
      }
    }
  
  }
  }
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
